import {v4 as UuidV4} from 'uuid'

export default {
    install(app) {
        app.config.globalProperties.$uuid = {
            generate() {
                return UuidV4()
            },
            obtain() {
                return UuidV4()
            }
        }
    }
}