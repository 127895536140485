import { createStore } from 'vuex'
import user from "@/store/user";

export default createStore({
  state: {
    dashboardDrawerShown: false
  },
  getters: {
    isDashboardDrawerShown: state => state.dashboardDrawerShown
  },
  actions: {
    setDashboardDrawerShown({commit}, shown) {
      commit('SET_DASHBOARD_DRAWER_SHOWN', shown)
    }
  },
  mutations: {
    SET_DASHBOARD_DRAWER_SHOWN(state, shown) {
      state.dashboardDrawerShown = shown
    }
  },
  modules: {
    user
  }
})
