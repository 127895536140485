import axios from "axios";
import extend from "@/util/extend";

function HttpClient(baseURL, $store) {
    this.config = {
        baseURL,
        headers: {'X-Requested-With':'XMLHttpRequest', 'Content-Type':'application/json'}
    }
    this.httpRequestList = {}
    this.$store = $store
}

HttpClient.prototype.create = function (config, requestId) {
    const $axios = axios.create(extend(this.config, config || {}))

    $axios.interceptors.request.use(request => {
        const accessToken = this.$store.getters['user/getAccessToken']
        if (accessToken) {
            request.headers["Authorization"] = 'Bearer ' + accessToken
        }

        if (requestId) {
            this.cancelRequest(requestId)
            this.addRequestId(requestId, ()=>{
                const httpClientSource = axios.CancelToken.source()
                request.cancelToken = httpClientSource.token
                return httpClientSource;
            })
        }

        return request;
    });

    $axios.interceptors.response.use(response => {
        return response
    }, error => {
        if (error.response) {
            const statusCode = error.response.status;
            const errorCode = error.response.data?.header?.error_code;

            if (
                (401 === statusCode && ('auth.unauthorized' === errorCode || "auth.expired_token" === errorCode)) ||
                (400 === statusCode && ('auth.invalid_token' === errorCode))
            ) {
                this.$store.dispatch('user/removeUser')
            }
        }

        return Promise.reject({...error});
    });

    return $axios;
}

HttpClient.prototype.addRequestId = function (requestId, source) {
    if (typeof source !== "function") {
        return;
    }

    if (!Object.prototype.hasOwnProperty.call(this.httpRequestList, requestId)) {
        this.httpRequestList[requestId] = [];
    }

    this.httpRequestList[requestId].push(source.call())
};

HttpClient.prototype.cancelRequest = function (requestId) {
    if (Object.prototype.hasOwnProperty.call(this.httpRequestList, requestId)) {
        const requests = this.httpRequestList[requestId];
        for (let x=0; x<requests.length; x++) {
            requests[x].cancel('canceled')
        }
    }
}

HttpClient.prototype.isRequestCanceled = function (error) {
    return error.message && 'canceled' === error.message
}

export default {
    install(app, pluginOptions) {
        const $store = app.config.globalProperties.$store
        if (!$store) {
            throw new Error("Http client is require vuex store. Please install it after they are already installed.")
        }

        app.config.globalProperties.$http = new HttpClient(pluginOptions.baseUrl, $store)
    }
}