<template>
    <GuestLayout>
        <template #navbar>
            <GuestNavbar>
                <template #navItemsEnd>
                    <li class="nav-item">
                        <a class="nav-link link-dark" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" href="#" @click="scrollToApiStack">Dok API</a>
                    </li>
                    <li class="nav-item">
                        <router-link to="/contact-us" class="nav-link link-dark">Bantuan</router-link>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link link-dark" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" href="#" @click="scrollToFeeStack">Biaya</a>
                    </li>
                </template>
            </GuestNavbar>
        </template>
        <template #default>
            <div class="bg-image" style="background-image: url('/assets/images/home/bannerbg.jpg');">
                <div class="container stack-1">
                    <h2 class="text-primary mb-3 head-text"> <b class="fw-bolder">SMS <span class="text-danger">Pay</span></b> <span class="head-text-child d-inline-block px-3 border border-2 border-primary rounded-5 glass-box">H2H</span></h2>
                    <p class="sub-head-text mb-0">Solusi Transaksi ke Bank Manapun seCEPAT dan seMUDAH</p>
                    <p class="sub-head-text">yang bisa kamu bayangkan</p>
                </div>
            </div>

            <div class="bg-white">
                <div class="container py-5 text-center">
                    <router-link to="/register" class="btn btn-lg btn-primary">Coba Sekarang</router-link>
                    <router-link to="/contact-us" class="btn btn-lg btn-outline-primary ms-2 ms-lg-5">
                        Hubungi Sales <fa-icon icon="angle-right" class="ms-2"/>
                    </router-link>
                </div>
            </div>

            <div class="bg-white">
                <div class="container py-5">
                    <h2 class="fs-5 text-primary">Kenapa Harus SMS Pay Bisnis</h2>
                    <p class="fs-2 mb-5">Solusi Terintegrasi untuk berbagai kebutuhan transaksi bisnis</p>

                    <div class="d-flex flex-column flex-lg-row" style="margin: 0 -1rem;">
                        <div class="bg-image border border-2 shadow mx-3 p-3 mb-4 mb-lg-0" style="background-image: url('/assets/images/home/securebg.jpg');">
                            <div class="py-3 py-lg-5 ms-lg-3">
                                <img src="/assets/images/home/secure-logo.jpg" alt="">
                            </div>
                            <h5 class="fs-2 mb-3">Aman</h5>
                            <p class="card-text">Transaksi di SMS Pay dijamin aman dan terlisensi Bank Indonesia.</p>
                        </div>
                        <div class="bg-image border border-2 shadow mx-3 p-3 mb-4 mb-lg-0" style="background-image: url('/assets/images/home/realtimebg.jpg');">
                            <div class="py-3 py-lg-5 ms-lg-3">
                                <img src="/assets/images/home/realtime-logo.jpg" alt="">
                            </div>
                            <h5 class="fs-2 mb-3">Realtime</h5>
                            <p class="card-text">Transfer ke Semua bank secara realtime dalam satu tempat.</p>
                        </div>
                        <div class="bg-image border border-2 shadow mx-3 p-3 mb-4 mb-lg-0" style="background-image: url('/assets/images/home/easybg.jpg');">
                            <div class="py-3 py-lg-5 ms-lg-3">
                                <img src="/assets/images/home/easy-logo.jpg" alt="">
                            </div>
                            <h5 class="fs-2 mb-3">Mudah</h5>
                            <p class="card-text">Akses lebih mudah dalam satu dashboard terintegrasi atau menggunakan integrasi API*.</p>
                        </div>
                        <div class="bg-image border border-2 shadow mx-3 p-3 mb-4 mb-lg-0" style="background-image: url('/assets/images/home/bulkbg.jpg');">
                            <div class="py-3 py-lg-5 ms-lg-3">
                                <img src="/assets/images/home/bulk-logo.jpg" alt="">
                            </div>
                            <h5 class="fs-2 mb-3">Bulk</h5>
                            <p class="card-text">10.000 transfer massal ke bank manapun sekaligus dalam beberapa klik.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-white stack-4">
                <div class="bg-image" style="background-image: url('/assets/images/home/transferbg.jpg');">
                    <div class="bg-trx-dsb" style="background-image: url('/assets/images/home/transfer-dashboard.jpg');">
                        <div class="container py-5">
                            <div class="row">
                                <div class="content col-lg-6">
                                    <h2 class="fs-1">SMS Pay Bisnis</h2>
                                    <p class="fs-2">dirancang untuk mendukung bisnis berskala kecil, menengah hingga besar</p>
                                    <p>Bertransaksi dalam jumlah besar tidak perlu memakan banyak waktu. Nikmati proses kirim uang yang praktis & instan bersama SMS Pay Bisnis. 10.000 transfer massal ke bank manapun sekaligus dalam beberapa klik.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div ref="apiStack" class="bg-white bg-image" style="background-image: url('/assets/images/home/integrasibg.jpg');">
                <div class="container py-5">
                    <div class="d-flex justify-content-between flex-column flex-column-reverse flex-lg-row">
                        <div>
                            <div class="d-flex flex-column flex-lg-row justify-content-center mb-3">
                                <div class="integration-item border shadow  p-3 rounded me-lg-3 mb-3 mb-lg-0 glass-box">
                                    <h3>Implementasi <br> Mudah</h3>
                                    <p>Kirim uang cukup melalui 1 dashboard utama</p>
                                </div>
                                <div class="integration-item border shadow  p-3 rounded glass-box">
                                    <h3>Waktu <br> Cepat</h3>
                                    <p>Layanan kami beroperasi 24/7, sehingga Anda bisa bertransaksi kapanpun</p>
                                </div>
                            </div>
                            <div class="d-lg-flex justify-content-lg-center">
                                <div class="integration-item border shadow  p-3 rounded me-lg-3 glass-box">
                                    <h3>Biaya <br> Hemat</h3>
                                    <p>Transaksi antar-bank dengan tarif lebih rendah</p>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-column justify-content-between mb-3 mb-lg-0 text-lg-end">
                            <h2 class="fs-1 mb-5">Integrasi API <br>yang mudah</h2>
                            <p class="">Hubungi developer terbaik untuk membantu proses integrasi <br> dengan cepat & efisien</p>
                            <div>
                                <a href="https://documenter.getpostman.com/view/25980185/2s93RWNBAf" target="_blank" class="btn btn-lg btn-primary">Dok API</a>
                                <router-link to="/contact-us" class="btn btn-lg btn-outline-primary ms-3">
                                    Bantuan Integrasi <fa-icon icon="angle-right" class="ms-2"/>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div ref="feeStack" class="bg-white">
                <div class="container py-5">
                    <h2 class="fs-5 text-primary">Biaya</h2>
                    <p class="fs-2">Apa pun bisnis Kamu, kami hanya akan memberikan biaya terbaik agar Kamu bisa meraih pendapatan yang maksimal.</p>

                    <div class="row g-5">
                        <div class="col-12 col-lg-6">
                            <p>Hanya bayar untuk tiap transaksi yang berhasil. Tanpa biaya integrasi, biaya pemeliharaan, atau biaya tersembunyi lainnya.</p>
                            <div class="row">
                                <div v-for="(bankName, i) in iconBankList" :key="i" class="col-4 d-flex align-items-center mb-3">
                                    <img :src="`/assets/images/${bankName}.png`" alt="" class="img-fluid">
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 text-lg-end">
                            <div class="text-start"><span class="fs-1 fw-bolder">Rp2.400<small class="text-danger">*</small></span><small class="text-muted">per transaksi</small></div>
                            <p>*Volume transaksi >3.000 per bulan? Kontak tim sales SMS Pay Bisnis untuk penawaran khusus terbaik.</p>
                            <router-link to="/contact-us" class="btn btn-lg btn-outline-primary">
                                Hubungi Sales <fa-icon icon="angle-right" class="ms-2" />
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="footer-top py-5 text-light" :style="{ backgroundImage: 'url(/assets/images/footer-home-page.jpg)' }">
                <div class="container">
                    <p class="text-center lead">Apa lagi yang kamu tunggu?</p>
                    <p class="text-center fs-2">Download Aplikasi SMS Pay Sekarang Juga.</p>
                    <figure class="wp-block-image size-large text-center"><img loading="lazy" width="196" height="62" src="/assets/images/google-play.png" alt="" class="wp-image-101"></figure>
                </div>
            </div>
            <GuestFooter/>
        </template>
    </GuestLayout>
</template>

<script>
import GuestLayout from "@/components/guest/GuestLayout";
import GuestNavbar from "@/components/guest/GuestNavbar";
import GuestFooter from "@/components/guest/GuestFooter";

export default {
    name: 'HomeView',
    setup() {
        return {
            iconBankList: ['bca', 'bni', 'bri', 'bsi', 'cimb-niaga', 'danamon', 'mandiri', 'pratama', 'puluhan-bank-lainnya']
        }
    },
    components: {GuestLayout, GuestNavbar, GuestFooter},
    methods: {
        scrollToApiStack() {
            this.$refs.apiStack?.scrollIntoView({
                behavior: 'smooth'
            })
        },
        scrollToFeeStack() {
            this.$refs.feeStack?.scrollIntoView({
                behavior: 'smooth'
            })
        }
    }
}
</script>

<style scoped>
.stack-1 {
    text-align: center;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}
.stack-1 .head-text {
    font-size: 3rem;
}
.stack-1 .head-text-child {
    font-size: 50%;
}
.footer-top {
    background-size:cover;
    background-repeat:no-repeat;
}
.stack-4 {
    padding-bottom: 5rem;
}
.bg-image {
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.bg-trx-dsb {
    background-size: auto 90%;
    background-repeat: no-repeat;
    background-position: right center;
}
.glass-box {
    background-color: #fff;
    background: rgba(255, 255, 255, 0.21);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.9px);
    -webkit-backdrop-filter: blur(9.9px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}
@media (max-width: 991px) {
    .stack-4 .content {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 16px;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        margin-top: 1rem;
    }
    .bg-trx-dsb {
        background-size: auto 200px;
        background-repeat: no-repeat;
        background-position: top 20px right;
    }
}
@media (min-width: 992px) {
    .glass-box {
        background-color: #fff;
    }
    .stack-1 {
        padding-top: 5rem !important;
        padding-bottom: 5rem !important;
    }
    .stack-1 .head-text {
        font-size: 7rem;
    }
    .stack-1 .head-text-child {
        font-size: 50%;
    }
    .stack-1 .sub-head-text {
        font-size: 2rem;
    }

    .integration-item {
        max-width: 310px;
    }
}
</style>
