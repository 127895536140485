import {isRoleGranted} from "@/util/userRole";

const redirectTo = function (name) {
    if (typeof name === "string" && name.trim() !== "") {
        return {name:name}
    }

    return {path:'/'}
};

export default {
    install(app, options) {
        const $router = app.config.globalProperties.$router
        const $store = app.config.globalProperties.$store

        if (!$router || !$store) {
            throw new Error("Router guard is require router and store. Please install it after they are already installed.")
        }

        $router.beforeEach(to=> {
            let requiredRoles = null

            const requireAuth = to.matched.some(route=>{
                const metaGuard = route.meta?.guard

                if (typeof metaGuard === "boolean") {
                    return metaGuard
                }

                if (typeof metaGuard === "string") {
                    requiredRoles = [metaGuard]
                    return true
                }

                if (Array.isArray(metaGuard)) {
                    requiredRoles = metaGuard
                    return true
                }

                return false
            })

            if (requireAuth) {
                const isAuthenticated = $store.getters['user/isAuthenticated'] || false
                if (isAuthenticated) {
                    if (requiredRoles !== null) {
                        const account = $store.getters['user/getAccount']
                        const hasRoles = account?.roles || []

                        if (!isRoleGranted(hasRoles, requiredRoles)) {
                            return redirectTo(options?.loginName ? options.loginName : 'Home')
                        }
                    }
                } else {
                    return redirectTo(options?.loginName ? options.loginName : 'Home')
                }
            }
        })
    }
}