const KEY_ACCOUNT = '_b2bacn'
const KEY_ACCESS_TOKEN = '_b2buat'
const KEY_MASTER_ACCOUNT_CONNECTED = '_b2bmac'

const getAccountStorage = ()=>{
    const account = localStorage.getItem(KEY_ACCOUNT)
    return account ? JSON.parse(account) : null
}

const setAccountStorage = account=>{
    localStorage.setItem(KEY_ACCOUNT, account ? JSON.stringify(account) : '')
}

const getMasterAccountStorage = ()=>{
    const connected = localStorage.getItem(KEY_MASTER_ACCOUNT_CONNECTED)
    return connected === 'yes'
}

const setMasterAccountStorage = connected => {
    localStorage.setItem(KEY_MASTER_ACCOUNT_CONNECTED, connected ? 'yes' : 'no')
}

const isEmpty = value => {
    return value === null || value === ''
}

export default {
    namespaced: true,
    state: {
        account: getAccountStorage(),
        accessToken: localStorage.getItem(KEY_ACCESS_TOKEN),
        masterAccountConnected: getMasterAccountStorage()
    },
    getters: {
        getAccount: state => state.account,
        getAccessToken: state => state.accessToken,
        isAuthenticated: state => !isEmpty(state.accessToken) && !isEmpty(state.account),
        isMasterAccountConnected: state => state.masterAccountConnected === true
    },
    actions: {
        setAccount({commit}, account) {
            commit('SET_ACCOUNT', account)
        },
        setAccessToken({commit}, token) {
            commit('SET_ACCESS_TOKEN', token)
        },
        setMasterAccountConnected({commit}, connected) {
            commit('SET_MASTER_ACCOUNT_CONNECTED', connected)
        },
        setUser({commit}, data) {
            commit('SET_USER', data)
        },
        removeUser({commit}) {
            commit('REMOVE_USER')
        }
    },
    mutations: {
        SET_ACCOUNT(state, account) {
            state.account = account
            setAccountStorage(account)
        },
        SET_ACCESS_TOKEN(state, token) {
            state.accessToken = token
            localStorage.setItem(KEY_ACCESS_TOKEN, token || '')
        },
        SET_MASTER_ACCOUNT_CONNECTED(state, connected) {
            state.masterAccountConnected = connected === true
            setMasterAccountStorage(connected)
        },
        SET_USER(state, data) {
            state.account = data?.account || null
            state.accessToken = data?.token || null
            state.masterAccountConnected = data?.masterAccountConnected || false

            setAccountStorage(state.account)
            setMasterAccountStorage(state.masterAccountConnected)
            localStorage.setItem(KEY_ACCESS_TOKEN, state.accessToken || '')
        },
        REMOVE_USER(state) {
            state.account = null
            state.accessToken = null
            state.masterAccountConnected = false
            localStorage.removeItem(KEY_ACCOUNT)
            localStorage.removeItem(KEY_ACCESS_TOKEN)
            localStorage.removeItem(KEY_MASTER_ACCOUNT_CONNECTED)
        }
    }
}